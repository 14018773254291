import { getDateNoTime } from '@assets/js/dateUtils';

export const subTableProperties = [
  {
    label: '产品类型',
    prop: 'podr_category',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: {
      type: 'select',
      options: [
        { value: '新款', label: '新款' },
        { value: '翻单', label: '翻单' }
      ],
      copyAndPaste: true,
      required: true
    }
  },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '中文名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '部件名称', prop: 'prod_partno', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '产品描述', prop: 'prod_desc', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '部件单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '数量', prop: 'podr_part_num', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '已出运数量', prop: 'prod_ship_already', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '单价(元)', prop: 'prod_price', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '金额(元)', prop: 'total_price', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '内包装数量', prop: 'prod_qpb', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外包装数量', prop: 'prod_qpc', itemType: 'input', input: false, sortable: false, labelWidth: 150, subItem: { required: true } },
  { label: '外包装单位', prop: 'prod_box_unit', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '箱数', prop: 'prod_box_num', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '长(CM)', prop: 'prod_boxl', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '宽(CM)', prop: 'prod_boxw', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '高(CM)', prop: 'prod_boxh', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外箱毛重', prop: 'total_gross_weight', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外箱净重', prop: 'total_net_weight', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '是否主商品', prop: 'prod_mainsup', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val === 1 ? '是' : '否') },
  {
    label: '收货工厂',
    prop: 'supp_bid',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    needOtherColumn: true,
    formatter: row => row.supp_babbr,
    subItem: { type: 'input', copyAndPaste: true }
  },
  {
    label: '要求交货日期',
    prop: 'podr_part_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: false, required: true }
  },
  {
    label: '实际交货日期',
    prop: 'podr_part_redate',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDateNoTime(val, false),
    labelWidth: 150,
    subItem: { type: 'date', copyAndPaste: false }
  },

  { label: '备注', prop: 'podr_part_remark', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '产品条形码', prop: 'prod_bar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '内包装条形码', prop: 'prod_inbar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '外包装条形码', prop: 'prod_outbar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') }
];
