<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
        <el-row>
          <!-- <el-col :md="8">
            <el-form-item label="我司货号:" prop="prod_no">
              <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写我司货号"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :md="7">
            <el-form-item label="部件名称:" prop="prod_partno">
              <el-input size="small" v-model.trim="searchForm.prod_partno" clearable placeholder="请填写部件名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="客户货号:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="searchForm.prod_cust_no" clearable placeholder="请填写客户货号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="10">
            <el-form-item label="中文名称:" prop="prod_name">
              <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写中文名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" class="vd_dis_right">
            <!-- <el-form-item label="供应商简称:" prop="supp_id">
              <el-select
                  v-model="searchForm.supp_id"
                  placeholder="请选择供应商简称"
                  size="small"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in suppList"
                    :key="item.supp_id"
                    :label="item.supp_abbr"
                    :value="item.supp_id"
                  >
                  </el-option>
                </el-select> 
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="我司货号" prop="prod_no"></el-table-column>
          <el-table-column label="货号后缀" prop="prod_poststfix" show-overflow-tooltip></el-table-column>
          <el-table-column label="部件名称" prop="prod_partno"></el-table-column>
          <el-table-column label="客户货号" prop="prod_cust_no"></el-table-column>
          <el-table-column label="中文名称" prop="prod_name"></el-table-column>
          <el-table-column label="规格" prop="prod_spec" show-overflow-tooltip></el-table-column>
          <el-table-column label="需申购数量" prop="scon_part_apply"></el-table-column>
          <el-table-column label="用量" prop="scon_part_num"></el-table-column>
          <el-table-column label="采购单价" prop="prod_price"></el-table-column>
          <el-table-column label="供应商简称" prop="supp_abbr"></el-table-column>
          <el-table-column label="是否主厂">
            <template slot-scope="scope">
              <span>{{ scope.row.prod_mainsup | formatMainShip }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col :md="24">
        <pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination">
        </pubPagination>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { prodAPI } from '@api/modules/prod';
import { sconAPI } from '@/api/modules/scon';
import { suppAPI } from '@api/modules/supp';
// import pubPagination from "@/components/common/pubPagination";

export default {
  name: 'ProdImport',
  components: {
    // pubPagination,
  },
  props: {
    sconId: {
      type: Number,
      required: true
    },
    suppId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        prod_no: null,
        prod_partno: null,
        prod_cust_no: null,
        prod_name: null,
        supp_id: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      suppList: [],
      prodId: null
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 是否主厂
    formatMainShip(row) {
      if (row === 1) {
        return '是';
      } else if (row === 0) {
        return '否';
      }
    }
  },
  methods: {
    initData() {
      this.getProdsList();
      this.getSupp();
    },
    getProdsList() {
      get(sconAPI.getSconPartBySuppId, {
        prod_partno: this.searchForm.prod_partno,
        prod_cust_no: this.searchForm.prod_cust_no,
        prod_name: this.searchForm.prod_name,
        prod_no: this.searchForm.prod_no,
        supp_id: this.suppId,
        scon_id: this.sconId,
        prod_id: this.prodId
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.scon_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        prod_no: null,
        prod_partno: null,
        prod_cust_no: null,
        prod_name: null,
        supp_id: null
      };
      this.loadFlag = true;
      // this.currentPage = 1
      this.initData();
      // this.$refs.pubPagination.currentPage = 1
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      // this.$refs.pubPagination.currentPage = 1
      // this.currentPage = 1
      this.initData();
    },
    // 分页查询
    // changePageSearch(val) {
    //   this.loadFlag = true
    //   this.currentPage = val
    //   this.getProdsList()
    // },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanel');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirm', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
