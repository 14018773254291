<template>
  <div class="podrProdList">
    <DynamicUTable
      :form="podrForm"
      :is-show="isShow"
      ref="multiTable"
      :columns="subTableProperties"
      :showSummary="true"
      :need-pagination="false"
      :needChangeColumnProperties="true"
      :isCopyColumn="true"
      :summariesColumns="['podr_part_num', 'prod_price', 'total_price', 'prod_qpb', 'prod_qpc', 'prod_box_num']"
      :table-data="podrForm.podr_part_list"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:prod_name="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_name'">
          <el-input v-model="scope.row.prod_name" disabled placeholder="暂无中文名称" show-word-limit>
            <template slot="append">
              <el-popover placement="bottom" width="600" trigger="click" @show="textEnlargementShow(1, scope.$index)">
                <textEnlargement
                  ref="textEnlargement"
                  :disabledFlag="true"
                  @textEnlargementChange="textEnlargementChange"
                  :textEnlargementString="textEnlargementString"
                ></textEnlargement>
                <span slot="reference"
                  ><el-link type="info"><i class="el-icon-more"></i></el-link
                ></span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_desc="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_desc'">
          <el-input v-model="scope.row.prod_desc" placeholder="暂无产品描述" show-word-limit>
            <template slot="append">
              <el-popover placement="bottom" width="600" trigger="click" @show="textEnlargementShow(0, scope.$index)">
                <textEnlargement
                  ref="textEnlargement"
                  :disabledFlag="false"
                  @textEnlargementChange="textEnlargementChange"
                  :textEnlargementString="textEnlargementString"
                ></textEnlargement>
                <span slot="reference"
                  ><el-link type="info"><i class="el-icon-more"></i></el-link
                ></span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_unit'">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :copy-data="scope.row.prod_unit"
            :index="scope.$index"
            :column="'prod_unit'"
            :is-show="isShow"
            :type="typeof scope.row.prod_unit"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: podrForm.podr_part_list, ...data })"
          >
            <template v-slot:elForm>
              <el-select v-model="scope.row.prod_unit" filterable placeholder="请选择外包装单位">
                <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:header-podr_part_num="scope"><span class="vg_deep_red">数量</span></template>
      <template v-slot:podr_part_num="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.podr_part_num'" :rules="[{ required: true }]">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'podr_part_num'"
            :copy-data="scope.row.podr_part_num"
            :index="scope.$index"
            :is-show="isShow"
            :type="typeof scope.row.podr_part_num"
            @changeData="
              data =>
                $refs[`CopyPasteCell${scope.$index}`].changeData({
                  list: podrForm.podr_part_list,
                  ...data,
                  method: totalPriceChange1,
                  methodArgs: { arg1: scope.$index, arg2: 0 }
                })
            "
          >
            <template v-slot:elForm>
              <el-input
                v-model="scope.row.podr_part_num"
                maxlength="10"
                placeholder="暂无数量"
                show-word-limit
                @change="totalPriceChange1(scope.$index, 0)"
                @input="scope.row.podr_part_num = keep6Decimal(scope.row.podr_part_num)"
              ></el-input>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:header-prod_price="scope"><span class="vg_deep_red">单价(元)</span></template>
      <template v-slot:prod_price="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_price'" :rules="[{ required: true }]">
          <el-input
            @change="totalPriceChange1(scope.$index, 1)"
            v-model="scope.row.prod_price"
            @input="val => (scope.row.prod_price = keep4Decimal(val))"
            maxlength="9"
            show-word-limit
            placeholder="暂无单价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_qpb="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_qpb'">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :copy-data="scope.row.prod_qpb"
            :index="scope.$index"
            :column="'prod_qpb'"
            :is-show="isShow"
            :type="typeof scope.row.prod_qpb"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: podrForm.podr_part_list, ...data })"
          >
            <template v-slot:elForm>
              <el-input
                v-model="scope.row.prod_qpb"
                @input="scope.row.prod_qpb = helper.keepTNum(scope.row.prod_qpb)"
                maxlength="10"
                show-word-limit
                placeholder="暂无内包装数量"
              ></el-input>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:header-prod_qpc="scope"><span class="vg_deep_red">外包装数量</span></template>
      <template v-slot:prod_qpc="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_qpc'" :rules="[{ required: true }]">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :copy-data="scope.row.prod_qpc"
            :index="scope.$index"
            :column="'prod_qpc'"
            :is-show="isShow"
            :type="typeof scope.row.prod_qpc"
            @changeData="
              data =>
                $refs[`CopyPasteCell${scope.$index}`].changeData({
                  list: podrForm.podr_part_list,
                  ...data,
                  method: prodBoxNumChange,
                  methodArgs: { arg1: scope.$index }
                })
            "
          >
            <template v-slot:elForm>
              <el-input
                @change="prodBoxNumChange(scope.$index)"
                v-model="scope.row.prod_qpc"
                @input="scope.row.prod_qpc = helper.keepTNum(scope.row.prod_qpc)"
                maxlength="10"
                show-word-limit
                placeholder="暂无外包装数量"
              ></el-input>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:prod_box_unit="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.prod_box_unit'">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :copy-data="scope.row.prod_box_unit"
            :index="scope.$index"
            :column="'prod_box_unit'"
            :is-show="isShow"
            :type="typeof scope.row.prod_box_unit"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: podrForm.podr_part_list, ...data })"
          >
            <template v-slot:elForm>
              <el-select v-model="scope.row.prod_box_unit" filterable placeholder="请选择外包装单位" size="small">
                <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:supp_bid="scope">
        <el-form-item :prop="'podr_part_list.' + scope.$index + '.supp_bid'" label-width="0">
          <el-select
            v-model="scope.row.supp_bid"
            filterable
            placeholder="暂无收货工厂"
            @change="val => (scope.row.supp_babbr = suppList.find(x => x.supp_id === val).supp_abbr)"
          >
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:header-podr_part_pedate="scope"><span class="vg_deep_red">要求交货日期</span></template>
      <template v-slot:podr_part_pedate="scope">
        <el-form-item :prop="'podr_part_list.' + scope.$index + '.podr_part_pedate'" :rules="[{ required: true }]" label-width="0">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :copy-data="scope.row.podr_part_pedate"
            :index="scope.$index"
            :column="'podr_part_pedate'"
            :is-show="isShow"
            :type="typeof scope.row.podr_part_pedate"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: podrForm.podr_part_list, ...data })"
          >
            <template v-slot:elForm>
              <el-date-picker v-model="scope.row.podr_part_pedate" placeholder="选择日期" type="date"></el-date-picker>
            </template>
          </CopyPasteCell>
        </el-form-item>
      </template>
      <template v-slot:podr_part_redate="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.podr_part_redate'">
          <el-date-picker v-model="scope.row.podr_part_redate" placeholder="选择日期" type="date"></el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:podr_part_remark="scope">
        <el-form-item label-width="0" :prop="'podr_part_list.' + scope.$index + '.podr_part_remark'">
          <el-input v-model="scope.row.podr_part_remark" maxlength="50" placeholder="暂无备注" show-word-limit></el-input>
        </el-form-item>
      </template>
    </DynamicUTable>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import CopyPasteCell from '@/components/table/CopyPasteCell.vue';
import { keep4Decimal, keep6Decimal } from '@assets/js/regExUtil';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { subTableProperties } from '@/views/SalesManagement/PodrManage/podr';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { debounce } from 'lodash';

export default {
  name: 'PartList',
  mixins: [myMixin],
  props: {
    podrForm: {
      type: Object,
      required: true
    },
    suppList: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean
    }
  },
  components: {
    DynamicUTable,
    CopyPasteCell,
    textEnlargement
  },
  data() {
    return {
      selectionsList: [],
      prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      fixedNum: 3,
      priceFlag: true,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      subTableProperties: subTableProperties
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    keep6Decimal,
    initData() {
      this.getProdRankList();
    },
    // 获取单位
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let total_price_index = '';
      const temp = ['podr_part_num', 'total_price', 'prod_price', 'prod_qpb', 'prod_qpc', 'prod_box_num'];
      columns.forEach((column, index) => {
        if (column.property === 'total_price') {
          total_price_index = index;
        }
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        if (temp.includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(4);
        }
      });
      if (Number(sums[total_price_index]) || Number(sums[total_price_index]) === 0) this.$emit('totalPriceChange', sums[total_price_index]);
      return sums;
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
      this.$emit('partSelect', this.selectionsList);
    },
    changeCell(val) {
      if (val.label === '客户货号') {
        this.fixedNum = 5;
      } else if (val.label === '中文名称') {
        this.fixedNum = 6;
      } else if (val.label === '中文规格') {
        this.fixedNum = 7;
      }
    },
    // 计算总价格
    totalPriceChange1(val, val1) {
      val = Number(val);
      this.podrForm.podr_part_list[val].podr_part_num = new BigNumber(this.podrForm.podr_part_list[val].podr_part_num).toFixed(2, 0);
      this.priceFlag = false;
      let totalA = new BigNumber(1);
      if (val1 === 1) {
        this.podrForm.podr_part_list[val].prod_price = this.helper.calcPrice(this.podrForm.podr_part_list[val].prod_price, 4, 10000);
      }
      totalA = totalA.times(this.podrForm.podr_part_list[val].podr_part_num).times(this.podrForm.podr_part_list[val].prod_price).toNumber();
      this.podrForm.podr_part_list[val].total_price = totalA;
      this.prodBoxNumChange(val);
      this.priceFlag = true;
      //计算主表金额
      this.$emit('totalPriceChange', objectArrayReduce(this.podrForm.podr_part_list, 'total_price'));
    },
    message: debounce(function () {
      this.$message.warning('箱数未能整除，已向上取整');
    }, 1000),
    // 计算箱数
    prodBoxNumChange(val) {
      if (Number(this.podrForm.podr_part_list[val].podr_part_num) % Number(this.podrForm.podr_part_list[val].prod_qpc) !== 0) {
        this.message();
      }
      let prodTgweight = new BigNumber(1);
      prodTgweight = prodTgweight
        .times(this.podrForm.podr_part_list[val].podr_part_num ? this.podrForm.podr_part_list[val].podr_part_num : 0)
        .div(this.podrForm.podr_part_list[val].prod_qpc ? this.podrForm.podr_part_list[val].prod_qpc : 0);
      if (prodTgweight.isFinite()) {
        this.podrForm.podr_part_list[val].prod_box_num = prodTgweight.toFixed(0, 0);
      }
    },
    // 计算收货地址
    suppAbbrChange(val) {
      for (let i in this.suppList) {
        if (this.suppList[i].supp_id === this.podrForm.podr_part_list[val].supp_bid) {
          this.podrForm.podr_part_list[val].supp_bid = this.suppList[i].supp_id;
          this.podrForm.podr_part_list[val].supp_babbr = this.suppList[i].supp_abbr;
          this.podrForm.podr_part_list[val].supp_contact = this.suppList[i].supp_contact;
          this.podrForm.podr_part_list[val].supp_phone = this.suppList[i].supp_phone;
          this.podrForm.podr_part_list[val].supp_addr = this.suppList[i].supp_addr;
        }
      }
      this.$emit('suppAbbrChange');
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.podrForm.podr_part_list[val1].prod_desc;
      } else if (val === 1) {
        this.textEnlargementString.str = this.podrForm.podr_part_list[val1].prod_name;
      } else if (val === 2) {
        this.textEnlargementString.str = this.podrForm.podr_part_list[val1].prod_spec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.podrForm.podr_part_list[this.textEnlargementNum].prod_desc = val;
      } else if (this.textEnlargementFlag === 1) {
        this.podrForm.podr_part_list[this.textEnlargementNum].prod_name = val;
      } else if (this.textEnlargementFlag === 2) {
        this.podrForm.podr_part_list[this.textEnlargementNum].prod_spec = val;
      }
    },
    copyColumn({ prop, itemType, formatter }) {
      let str = '';
      this.podrForm.podr_part_list.forEach(item => {
        if (itemType === 'date') str += item[prop] ? formatter(item[prop]) + '\n' : '\n';
        else str += item[prop] + '\n';
      });
      navigator.clipboard.writeText(str);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
::v-deep .fixed-cell {
  background-color: #ccc !important;
}
</style>
