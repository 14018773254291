<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <AddHeader
      :btn="btn"
      :isShow="isShow"
      :special="true"
      :strForm="{ status: podrForm.status }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="buttonRefresh"
    />
    <el-form ref="podrForm" :model="podrForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="销售合同号" prop="scon_no">
            <el-input maxlength="8" v-model="podrForm.scon_no" placeholder="请填写销售合同号" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="下单状态" prop="podr_stat">
            <el-select filterable v-model="podrForm.podr_stat" :disabled="podrForm.status === 2" placeholder="请选择下单状态">
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="合同号备注" prop="podr_note">
            <el-input maxlength="10" v-model="podrForm.podr_note" placeholder="请填写单据提示" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_id">
            <el-input disabled v-model="podrForm.supp_abbr" placeholder="请填写供应商简称" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_id">
            <el-select size="small" filterable v-model="podrForm.cptt_id" placeholder="请选择公司抬头">
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="合同日期" prop="podr_cndate">
            <el-date-picker v-model="podrForm.podr_cndate" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-select v-model="podrForm.cust_abbr" placeholder="请选择" filterable clearable>
              <el-option v-for="item in custList" :key="item.value" :value="item.label" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="最终客户" prop="cust_bid">
            <el-select v-model="podrForm.cust_bid" clearable filterable placeholder="请选择最终客户">
              <el-option v-for="item in custList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="要求交货日期" prop="podr_pedate">
            <el-date-picker v-model="podrForm.podr_pedate" type="date" placeholder="选择日期" @change="shdateChange"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="销售经办人" prop="cust_stff_id">
            <el-select filterable @change="custStffIdChange" v-model="podrForm.cust_stff_id" placeholder="请选择外销经办人">
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售所属部门" prop="cust_dept_id">
            <el-select filterable v-model="podrForm.cust_dept_id" placeholder="请选择外销所属部门">
              <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="工厂账期" prop="supp_creday">
            <el-input
              @change="podrPpdateChange"
              maxlength="3"
              v-model="podrForm.supp_creday"
              @input="podrForm.supp_creday = helper.keepTNum(podrForm.supp_creday)"
              placeholder="请填写工厂账期"
              show-word-limit
            >
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="podr_stff_id">
            <el-select filterable @change="podrStffIdChange" v-model="podrForm.podr_stff_id" placeholder="请选择采购经办人">
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购所属部门" prop="podr_dept_id">
            <el-select filterable v-model="podrForm.podr_dept_id" placeholder="请选择采购所属部门">
              <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="预付款日期" prop="podr_ppdate">
            <el-date-picker v-model="podrForm.podr_ppdate" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="货款金额" prop="podr_prtotal">
            <el-input disabled v-model="podrForm.podr_prtotal" placeholder="请填写货款金额" show-word-limit>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用金额" prop="podr_fetotal">
            <el-input disabled v-model="podrForm.podr_fetotal" placeholder="请填写费用金额" show-word-limit>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="结算方式" prop="podr_payway">
            <el-select filterable v-model="podrForm.podr_payway" placeholder="请选择">
              <el-option v-for="item in settList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="合同总额" prop="podr_total">
            <el-input disabled v-model="podrForm.podr_total" placeholder="请填写合同总额" show-word-limit>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="草拟导入验货" prop="podr_inspection" :rules="[{ required: $cookies.get('userInfo').dept_id == '28', message: ' ' }]">
            <el-select clearable v-model="podrForm.podr_inspection" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: 0, label: '否' },
                  { value: 1, label: '是' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="AQL标准" prop="aql_standard">
            <el-select clearable v-model="podrForm.aql_standard" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: '0.65', label: '0.65' },
                  { value: '1.0', label: '1.0' },
                  { value: '1.5', label: '1.5' },
                  { value: '2.5', label: '2.5' },
                  { value: '4.0', label: '4.0' },
                  { value: '6.0', label: '6.0' },
                  { value: '全检', label: '全检' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-select filterable v-model="podrForm.cust_dcountry" placeholder="请选择目的国">
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-select filterable v-model="podrForm.cust_dport" placeholder="请选择目的港">
              <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号" prop="scon_cust_no">
            <el-input v-model="podrForm.scon_cust_no" maxlength="20" placeholder="请填写客户订单号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <div class="vd_but vg_mb_5">
          <el-button type="primary" plain size="small" @click="importPart">导入部件</el-button>
          <el-button type="danger" plain size="small" @click="deletePart">删除</el-button>
        </div>
        <PartsList
          @totalPriceChange="totalPriceChange"
          @suppAbbrChange="suppAbbrChange"
          @partSelect="partSelect"
          :podrForm="podrForm"
          :suppList="suppList"
        ></PartsList>
      </el-row>
      <div class="vd_dash"></div>
      <el-row>
        <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
          <el-tab-pane label="费用明细" name="first" :key="'first'">
            <div class="vd_but vg_mb_5">
              <el-button type="success" plain size="small" @click="feesAdd">新增</el-button>
              <el-button type="danger" plain size="small" @click="feesDelete">删除</el-button>
            </div>
            <CostList @totalExpenses="totalExpenses" :podrForm="podrForm" @feesSelect="feesSelect"> </CostList>
          </el-tab-pane>
          <el-tab-pane label="条款明细" name="second" :key="'second'">
            <CaluseList :isShow="isShow" :podrForm="podrForm"></CaluseList>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-dialog title="部件" :visible.sync="dialogVisible" width="70%">
        <PartImport
          @childConfirm="childConfirm"
          @childChanel="childChanel"
          :sconId="podrForm.scon_id"
          :suppId="podrForm.supp_id"
          ref="PartImport"
          :isShow="isShow"
        ></PartImport>
      </el-dialog>
    </el-form>
    <el-dialog title="生成成品采购变更单" :visible.sync="dialogVisible1" width="70%">
      <ImportProd :podrId="podrForm.podr_id" :prodList="podrForm.podr_part_list" @generateCancel="generateCancel"></ImportProd>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { podrAPI } from '@/api/modules/podr';
import { suppAPI } from '@api/modules/supp';
import { stffAPI } from '@api/modules/staff';
import { deptAPI } from '@api/modules/department';
import { cpttAPI } from '@api/modules/comptitle';
import { get, postNoCatch } from '@/api/request';
import PartsList from './Component/PartsList.vue';
import CostList from './Component/CostList.vue';
import CaluseList from './Component/ClauseList.vue';
import PartImport from './Component/PartImport.vue';
import ImportProd from './Component/ImportProd.vue';
import { BigNumber } from 'bignumber.js';
import { getCustomerInfo, getDataCenterByPermId } from '@api/public';
import AddHeader from '@/views/component/addHeader.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'podrEditMain',
  components: {
    AddHeader,
    editHeader,
    inputUser,
    PartsList,
    CostList,
    CaluseList,
    PartImport,
    ImportProd
  },
  data() {
    return {
      rules: {
        supp_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        podr_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        podr_pedate: [{ required: false, message: ' ', trigger: 'blur' }],
        podr_ppdate: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      isShow: false,
      btn: {},
      stffForm: {},
      podrForm: {
        podr_no: null,
        scon_no: null,
        podr_stat: null,
        podr_note: null,
        supp_id: null,
        supp_abbr: null,
        cptt_id: null,
        cptt_name: null,
        podr_cndate: null,
        podr_pedate: null,
        cust_stff_id: null,
        cust_stff_name: null,
        cust_dept_id: null,
        cust_dept_name: null,
        supp_creday: null,
        podr_stff_id: null,
        podr_stff_name: null,
        podr_dept_id: null,
        podr_dept_name: null,
        podr_ppdate: null,
        podr_prtotal: null,
        podr_fetotal: null,
        podr_payway: null,
        podr_total: null,
        aql_standard: null,
        podr_fees_list: [],
        podr_part_list: []
      },
      stateList: [
        { id: 0, label: '未下单' },
        { id: 1, label: '已下单' }
      ],
      suppList: [],
      activeName: 'first',
      loadingFlag: true,
      stffUserList: [],
      cpttList: [],
      deptList: [],
      settList: [
        { id: 1, label: '我司结算' },
        { id: 2, label: '工厂结算' }
      ],
      feesKey: 0,
      feesSelectList: [],
      partSelectList: [],
      dialogVisible: false,
      dialogVisible1: false,
      feesListCopy: [],
      partListCopy: [],
      custCountryList: [],
      custDportList: [],
      isBussinessDept: false,
      custList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.loadingFlag = false;
      let syncData = this.$route.params.syncData;
      if (syncData) {
        this.podrForm = syncData;
        this.stffForm.user_id = this.podrForm.user_id;
        this.stffForm.stff_id = this.podrForm.stff_id;
        this.stffForm.stff_name = this.podrForm.stff_name;
        this.stffForm.dept_id = this.podrForm.dept_id;
        this.stffForm.dept_name = this.podrForm.dept_name;
        this.stffForm.dept_team_id = this.podrForm.dept_team_id;
        this.stffForm.dept_team_name = this.podrForm.dept_team_name;
      }
      this.getSupp();
      this.getStffUser();
      this.getDept();
      this.getCptt();
      this.custList = await getCustomerInfo({ status: 2, type: 'abbr' });
      this.custCountryList = await getDataCenterByPermId({ id: 10003 });
      this.custDportList = await getDataCenterByPermId({ id: 10018 });
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.$refs.podrForm.resetFields();
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    submit(formName) {
      if (this.podrForm.podr_pedate < this.podrForm.podr_cndate) return this.$message.warning('要求交货期必须大于等于合同日期!');
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.podrForm.validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(err => {
          console.info('🚀 ~ file:PodrAddMain method: line:395 -----', err);
        });
    },
    saveInfo() {
      const props = this.$route.query.key;
      let podrForm = JSON.parse(JSON.stringify(this.podrForm));
      podrForm.personal = this.$cookies.get('userInfo').personal;
      if (podrForm.podr_cndate) {
        podrForm.podr_cndate = parseInt(new Date(podrForm.podr_cndate).getTime() / 1000);
      }
      if (podrForm.podr_pedate) {
        podrForm.podr_pedate = parseInt(new Date(podrForm.podr_pedate).getTime() / 1000);
      }
      if (podrForm.podr_ppdate) {
        podrForm.podr_ppdate = parseInt(new Date(podrForm.podr_ppdate).getTime() / 1000);
      }
      for (let i = 0; i < podrForm.podr_part_list.length; i++) {
        if (podrForm.podr_part_list[i].podr_part_pedate) {
          podrForm.podr_part_list[i].podr_part_pedate = parseInt(new Date(podrForm.podr_part_list[i].podr_part_pedate).getTime() / 1000);
        }
        if (podrForm.podr_part_list[i].podr_part_redate) {
          podrForm.podr_part_list[i].podr_part_redate = parseInt(new Date(podrForm.podr_part_list[i].podr_part_redate).getTime() / 1000);
        }
      }
      postNoCatch(podrAPI.addPdrToProd, podrForm).then(async ({ data }) => {
        await this.$EventBus.$emit('close_current_tags');
        await this.jump('/podr_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 127,
              form_id: data.form_id
            })
          )
        });
      });
    },
    // 生成成品采购变更单弹框
    generateProd() {
      this.dialogVisible1 = true;
    },
    changeStatus() {
      podrAPI.editPodrStat({ podr_id: this.podrForm.podr_id }).then(() => {
        this.initData();
        this.$message.success('下单成功!');
      });
    },
    // 生成成品采购变更单弹框关闭
    generateCancel() {
      this.dialogVisible1 = false;
    },
    clickPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.podrForm.podr_id;
          get(podrAPI.exportPodrPdfById, { podr_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 外销经办人
    custStffIdChange(val) {
      for (let i = 0; i < this.stffUserList.length; i++) {
        if (this.stffUserList[i].stff_id === val) {
          this.podrForm.cust_dept_id = this.stffUserList[i].dept_id;
        }
      }
    },
    // 采购经办人
    podrStffIdChange(val) {
      for (let i = 0; i < this.stffUserList.length; i++) {
        if (this.stffUserList[i].stff_id === val) {
          this.podrForm.podr_dept_id = this.stffUserList[i].dept_id;
        }
      }
    },
    // 计算货款金额
    totalPriceChange(val) {
      if (!val) return;
      let temp = JSON.parse(JSON.stringify(val));
      temp = Math.round(Number(temp) * 100) / 100;
      this.podrForm.podr_prtotal = this.helper.reservedDigits(temp);
      this.calcPrice();
    },
    // 计算费用总额
    totalExpenses(val) {
      this.podrForm.podr_fetotal = val;
      this.calcPrice();
    },
    // 计算合同总额
    calcPrice() {
      //部件子表金额之和 + 费用金额之和
      this.podrForm.podr_total = new BigNumber(this.podrForm.podr_fetotal).plus(this.podrForm.podr_prtotal).toFixed(2);
    },
    // 计算预付款日期
    podrPpdateChange() {
      if (this.podrForm.supp_creday && this.podrForm.podr_pedate) {
        this.podrForm.podr_ppdate = Number(this.podrForm.supp_creday) * 24 * 60 * 60 * 1000 + new Date(this.podrForm.podr_pedate).getTime();
      }
    },
    // 部件bufen
    // 计算收货地地址
    suppAbbrChange() {
      this.calcAddress();
    },
    // 删除每一行
    deletePart() {
      this.podrForm.podr_part_list = this.podrForm.podr_part_list.filter(item => this.partSelectList.every(item1 => item.scon_part_id !== item1.scon_part_id));
      this.calcAddress();
    },
    // 打开弹框
    importPart() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.PartImport.initData();
      }, 300);
    },
    // 部件选择
    partSelect(value) {
      let val = JSON.parse(JSON.stringify(value));
      this.partSelectList = val;
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.podrForm.podr_part_list.every(item1 => item.scon_part_id !== item1.scon_part_id));
      for (let i = 0; i < val.length; i++) {
        val[i].supp_bid = this.podrForm.supp_id;
        val[i].prod_box_unit = 'CTNS';
      }
      this.podrForm.podr_part_list = this.podrForm.podr_part_list.concat(val);
      this.dialogVisible = false;
    },

    // 新增费用明细
    feesAdd() {
      let item = {
        podr_fees_name: '',
        podr_fees_usd: '',
        podr_fees_remark: '',
        key: this.feesKey
      };
      this.podrForm.podr_fees_list.push(item);
      this.feesKey++;
    },
    // 费用选择
    feesSelect(val) {
      this.feesSelectList = JSON.parse(JSON.stringify(val));
    },
    // 费用删除
    feesDelete() {
      this.podrForm.podr_fees_list = this.podrForm.podr_fees_list.filter(item => this.feesSelectList.every(item1 => item.key !== item1.key));
      for (let i = 0; i < this.podrForm.podr_fees_list.length; i++) {
        this.podrForm.podr_fees_list[i].key = Number(i);
      }
      this.feesKey = this.podrForm.podr_fees_list.length;
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取部门信息
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 计算地址
    calcAddress() {
      /*      let addStr = [];
      let addStrCopy = [];
      if (this.podrForm.podr_part_list.length > 0) {
        for (let i = 0; i < this.podrForm.podr_part_list.length; i++) {
          let temp = '';
          temp = this.podrForm.podr_part_list[i].supp_babbr;
          if (this.podrForm.podr_part_list[i].supp_contact) {
            temp = temp + this.podrForm.podr_part_list[i].supp_contact;
          }
          if (this.podrForm.podr_part_list[i].supp_phone) {
            temp = temp + this.podrForm.podr_part_list[i].supp_phone;
          }
          if (this.podrForm.podr_part_list[i].supp_babbr) {
            let temp1 = false;
            // temp = temp + this.podrForm.podr_part_list[i].supp_babbr
            for (let j = 0; j < addStrCopy.length; j++) {
              if (addStrCopy[j] === this.podrForm.podr_part_list[i].supp_babbr) {
                temp1 = true;
              }
            }
            if (!temp1) {
              addStrCopy.push(this.podrForm.podr_part_list[i].supp_babbr);
              addStr.push(temp);
            }
          }
        }
        this.podrForm.podr_address = addStr.join(',');
      } else {
        this.podrForm.podr_address = '';
      }*/
    },
    //交货日期选择
    shdateChange(evt) {
      if (this.podrForm.supp_creday) {
        this.podrForm.podr_ppdate = new Date(evt).getTime() + this.podrForm.supp_creday * 86400000;
      } else {
        this.podrForm.podr_ppdate = evt;
      }
      for (let i = 0; i < this.podrForm.podr_part_list.length; i++) {
        this.podrForm.podr_part_list[i].podr_part_pedate = this.podrForm.podr_pedate;
      }
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
