<template>
  <div class="sconProdList">
    <el-row justify="space-between">
      <el-col :md="12">
        <el-button type="primary" plain size="small" @click="generateConfirm">确定</el-button>
        <el-button type="danger" plain size="small" @click="generateCancel">取消</el-button>
      </el-col>
    </el-row>
    <div class="vg_mb_16"></div>
    <el-table :data="prodList" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号" prop="prod_no"> </el-table-column>
      <el-table-column label="客户货号" prop="prod_cust_no"> </el-table-column>
      <el-table-column label="中文名称" prop="prod_name">
        <template slot-scope="scope">
          <span v-if="scope.row.prod_name">
            {{ scope.row.prod_name }}
          </span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="部件名称" prop="prod_partno">
        <template slot-scope="scope">
          <span v-if="scope.row.prod_partno">
            {{ scope.row.prod_partno }}
          </span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="中文规格" prop="prod_spec">
        <template slot-scope="scope">
          <span v-if="scope.row.prod_spec">
            {{ scope.row.prod_spec }}
          </span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="podr_part_num"> </el-table-column>
      <el-table-column label="单价" prop="prod_price"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { podcAPI } from '@/api/modules/podc';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ImportProd',
  props: {
    prodList: {
      type: Array,
      required: true
    },
    podrId: {
      type: Number,
      required: true
    }
  },
  components: {},
  data() {
    return {
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    // 取消生成
    generateCancel() {
      this.$emit('generateCancel');
    },
    // 确认生成
    generateConfirm() {
      if (this.selectionsList.length > 0) {
        this.$confirm('确定生成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let ids = [];
            for (let i = 0; i < this.selectionsList.length; i++) {
              ids.push(this.selectionsList[i].podr_part_id);
            }
            let temp = {};
            temp.podr_id = this.podrId;
            temp.podr_part_ids = ids.join(',');
            post(podcAPI.addPodc, temp)
              .then(res => {
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '生成成功'
                  });
                  this.generateCancel();
                  this.jump('/podc_edit', {
                    key: UrlEncode.encode(
                      JSON.stringify({
                        perm_id: 135,
                        form_id: res.data.data.form_id
                      })
                    )
                  });
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                }
              })
              .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生成'
            });
          });
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    }
  }
};
</script>

<style scoped lang="scss"></style>
