<template>
  <div class="podrProdList">
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="测试样时间" :prop="'testspecimens_date'">
          <el-date-picker v-model="podrForm.testspecimens_date" value-format="timestamp" clearable size="small" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="大货样时间" :prop="'largesamples_date'">
          <el-date-picker v-model="podrForm.largesamples_date" value-format="timestamp" clearable size="small" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="箱规提供时间" :prop="'boxgauge_date'">
          <el-date-picker v-model="podrForm.boxgauge_date" value-format="timestamp" clearable size="small" type="date" placeholder="选择日期"> </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; text-align: right; padding-right: 10px">
            <div>条款内容</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="openText(1)">选择</el-link>
          </div>
          <el-input v-model="podrForm.clau_content" :rows="3" maxlength="5000" placeholder="请填写条款内容" show-word-limit type="textarea"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; text-align: right; padding-right: 10px">
            <div>送货地址</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="openText(2)">选择</el-link>
          </div>
          <el-input v-model="podrForm.podr_address" type="textarea" maxlength="500" :rows="3" show-word-limit placeholder="请填写送货地址"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; text-align: right; padding-right: 10px">
            <div>唛头</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="openText(3)">选择</el-link>
          </div>
          <el-input v-model="podrForm.cust_shipmark" type="textarea" :rows="3" show-word-limit maxlength="500" placeholder="请填写唛头"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-form-item label="备注" :prop="'podr_remark'">
          <el-input v-model="podrForm.podr_remark" type="textarea" maxlength="500" :rows="3" show-word-limit placeholder="请填写备注"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog title="文本信息" :visible.sync="dialogVisible" width="70%">
      <ClauseImport @importClauChange="importClauChange"></ClauseImport>
    </el-dialog>
  </div>
</template>

<script>
import ClauseImport from './ClauseImport.vue';

export default {
  name: 'PartList',
  props: {
    podrForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ClauseImport
  },
  data() {
    return {
      selectionsList: [],
      prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      dialogVisible: false,
      inputType: null
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    importClauChange(row) {
      if (this.inputType === 1) {
        this.podrForm.clau_content = row.clau_content;
      } else if (this.inputType === 2) {
        this.podrForm.podr_address = row.clau_content;
      } else {
        this.podrForm.cust_shipmark = row.clau_content;
      }
      this.dialogVisible = false;
    },
    openText(val) {
      this.dialogVisible = true;
      this.inputType = val;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
.vd_flex {
  display: flex;
}
.vd_wid {
  width: 50px;
}
</style>
